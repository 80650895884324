<template>
  <div>
    <Component :is="pageType" v-if="pageType !== ''" :pagedata="data?.data" />
    <LayoutBreadcrumb v-if="data?.data?.breadcrumbs?.length" :pagedata="data?.data" />
  </div>
  
</template>

<script setup lang="ts">
import type { Ref } from 'vue'

const pageTypes = [
  'ContentPageType',
  'ErrorPageType',
  'FaqPageType',
  'KontaktPageType',
  'LegalPageType',
  'ProductPageType',
  'RedirectPageType',
  'SearchPageType',
  'SitemapPageType',
  'PhotoCreditsPageType'
]

const route = useRoute()
const { $backend } = useNuxtApp()
const { data, error } = await $backend.pages.getPageByUrlPath(
  encodeURI(route.path),
  encodeURI(route.fullPath)
)

// const title: string = data.value?.data?.urldata?.title ?? ''
const pageTypeTemp: string = data.value?.data?.urldata?.pagetype ?? ''
const pageType: Ref<string> = ref(
  pageTypes.includes(pageTypeTemp) ? pageTypeTemp : ''
)

/*
useSeoMeta({
  title,
  ogTitle: title
  // TODO: Set data.value.data.pagedata.urldata.description field in the backend
  // description: data.value?.data?.pagedata?.urldata?.description ?? '',
  // ogDescription: data.value?.data?.pagedata?.urldata?.description ?? '',
  // ogImage: 'https://example.com/image.png',
  // twitterCard: 'summary_large_image',
})
*/

useHead(computed(() => data.value?.data?.urldata?.head))

if (error.value !== null) {
  // eslint-disable-next-line no-console
  console.error(error.value)
}
if (pageTypeTemp && pageType.value === '') {
  // eslint-disable-next-line no-console
  console.error(`Error: ${pageTypeTemp} is not registered in [...slug].vue`)
}
</script>
